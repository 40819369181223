export class UrlSearchParamsParser {
  public params: URLSearchParams;

  constructor(search: string) {
    this.params = new URLSearchParams(search);
  }

  public get(key: string) {
    const value = this.params.get(key);
    return value !== null ? decodeURIComponent(value) : null;
  }

  public has(key: string) {
    return this.params.has(key);
  }
}
